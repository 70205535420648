<template>
  <v-list class="py-0">
    <v-list-tile class="py-3" height="54">
      <v-list-tile-content></v-list-tile-content>
      <v-list-tile-action>
        <v-btn fab class="elevation-0" @click="close()">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">home</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Home</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/itineraries/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">stars</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Itineraries</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/login/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">open_in_browser</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Login</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/new-agency-registration/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">assignment_ind</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Register</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/about/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">import_contacts</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>About</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/group-travel/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">group</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Groups</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/destinations/australia/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">explore</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Destinations - Australia</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/destinations/new-zealand/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">explore</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Destinations - New Zealand</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/the-insider/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">description</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>The ANZCRO Insider</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile to="/contact/">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">mail_outline</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Contact</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-list-tile href="tel:+6434410032">
      <v-list-tile-action>
        <v-icon color="#d6d6d5">phone</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Call +64 3 441 0032</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>
<script>
export default {
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>

