import Public from '@/components/public/Public.COM';
import Home from '@/components/public/Home.COM';
import Register from '@/components/public/registration/Register.COM';
import AgencyRegistration from '@/components/public/registration/AgencyRegistration.COM';
import About from '@/components/public/About.COM';
import Contact from '@/components/public/Contact.COM';
//import Suppliers from '@/components/public/Suppliers';
import Groups from '@/components/public/Groups.COM';
import Login from '@/components/login';
import RecoverPassword from '@/components/public/RecoverPassword';
import ResetPassword from '@/components/public/ResetPassword';
import ThankyouRegister from '@/components/public/registration/ThankYouRegister';
import ThankyouAgencyRegistration from '@/components/public/registration/ThankYouAgencyRegistration';
import VerifiedLogin from '@/components/public/VerifiedLogin';
import ThankyouContact from '@/components/public/ThankYouContact';
import PrivacyPolicy from '@/components/public/PrivacyPolicy';
import TermsConditions from '@/components/public/TermsConditions';
import WebsiteTerms from '@/components/public/WebsiteTerms';
import Sustainability from '@/components/public/SustainabilityPolicy';
import Brochures from '@/components/public/Brochures';

import PromoNorthAmerica from '@/components/public/PromoNorthAmerica.COM';
import Itineraries from '@/components/public/Itineraries.COM';

const Blog = () => import("@/components/public/blog/Blog");
const BlogPost = () => import("@/components/public/blog/BlogPost");

import Destinations from '@/components/public/destinations/Destinations';
import NewZealandDestinations from '@/components/public/destinations/NewZealand';
import AustraliaDestinations from '@/components/public/destinations/Australia';

import DestinationAuckland from '@/components/public/destinations/north-island/DestinationAuckland';
import DestinationWellington from '@/components/public/destinations/north-island/DestinationWellington';
import DestinationHawkesBay from '@/components/public/destinations/north-island/DestinationHawkesBay';
import DestinationTairawhitiGisborne from '@/components/public/destinations/north-island/DestinationTairawhitiGisborne';
import DestinationTaranaki from '@/components/public/destinations/north-island/DestinationTaranaki';
import DestinationManawatu from '@/components/public/destinations/north-island/DestinationManawatu';
import DestinationWairarapa from '@/components/public/destinations/north-island/DestinationWairarapa';
import DestinationWhanganui from '@/components/public/destinations/north-island/DestinationWhanganui';
import DestinationBayOfPlenty from '@/components/public/destinations/north-island/DestinationBayOfPlenty';
import DestinationCoromandel from '@/components/public/destinations/north-island/DestinationCoromandel';
import DestinationNorthland from '@/components/public/destinations/north-island/DestinationNorthland';
import DestinationRotorua from '@/components/public/destinations/north-island/DestinationRotorua';
import DestinationRuapehu from '@/components/public/destinations/north-island/DestinationRuapehu';
import DestinationTaupo from '@/components/public/destinations/north-island/DestinationTaupo';
import DestinationWaikato from '@/components/public/destinations/north-island/DestinationWaikato';

import DestinationCentralOtago from '@/components/public/destinations/south-island/DestinationCentralOtago';
import DestinationQueenstown from '@/components/public/destinations/south-island/DestinationQueenstown';
import DestinationDunedin from '@/components/public/destinations/south-island/DestinationDunedin';
import DestinationChristchurchCanterbury from '@/components/public/destinations/south-island/DestinationChristchurchCanterbury';
import DestinationFiordland from '@/components/public/destinations/south-island/DestinationFiordland';
import DestinationNelsonTasman from '@/components/public/destinations/south-island/DestinationNelsonTasman';
import DestinationSouthland from '@/components/public/destinations/south-island/DestinationSouthland';
import DestinationWanaka from '@/components/public/destinations/south-island/DestinationWanaka';
import DestinationMarlborough from '@/components/public/destinations/south-island/DestinationMarlborough';
import DestinationWestCoast from '@/components/public/destinations/south-island/DestinationWestCoast';
import DestinationKaikoura from '@/components/public/destinations/south-island/DestinationKaikoura';
import DestinationHurunui from '@/components/public/destinations/south-island/DestinationHurunui';
import DestinationClutha from '@/components/public/destinations/south-island/DestinationClutha';
import DestinationTimaru from '@/components/public/destinations/south-island/DestinationTimaru';

import DestinationACT from '@/components/public/destinations/australia/DestinationACT';
import DestinationNSW from '@/components/public/destinations/australia/DestinationNSW';
import DestinationNT from '@/components/public/destinations/australia/DestinationNT';
import DestinationQLD from '@/components/public/destinations/australia/DestinationQLD';
import DestinationSA from '@/components/public/destinations/australia/DestinationSA';
import DestinationTAS from '@/components/public/destinations/australia/DestinationTAS';
import DestinationVIC from '@/components/public/destinations/australia/DestinationVIC';
import DestinationWA from '@/components/public/destinations/australia/DestinationWA';

export const publicRoutes = [
  {
    path: '/',
    component: Public,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
        meta: {
          title: 'ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      {
        path: 'register/',
        name: 'register',
        component: Register,
      },
      {
        path: 'new-agency-registration/',
        name: 'agencyregistration',
        component: AgencyRegistration,
      },
      {
        path: 'agent-account-requested/',
        name: 'thankyouRegister',
        component: ThankyouRegister,
      },
      {
        path: 'agency-registration-requested/',
        name: 'thankyouAgencyRegistration',
        component: ThankyouAgencyRegistration,
      },
      {
        path: 'about/',
        name: 'about',
        component: About,
      },
      {
        path: 'contact/',
        name: 'contact',
        component: Contact,
        meta: {
          title: 'Contact Us - ANZCRO | The Australian and New Zealand Holiday Experts',
          description:
            'Contact Us - ANZCRO - The Australian and New Zealand Holiday Experts. Send us an email or contact our reservations office on the telephone numbers provided.',
        },
      },
      /*
      {
        path: 'suppliers/',
        name: 'suppliers',
        component: Suppliers,
      },
      */
      {
        path: 'brochures/',
        name: 'brochures',
        component: Brochures,
      },
      {
        path: 'itineraries/',
        name: 'itineraries',
        component: Itineraries,
      },
      {
        path: 'group-travel/',
        name: 'groups',
        component: Groups,
      },
      {
        path: 'login/',
        name: 'login',
        component: Login,
      },
      {
        path: 'terms-and-conditions/',
        name: 'termsConditions',
        component: TermsConditions,
      },
      {
        path: 'website-terms/',
        name: 'websiteTerms',
        component: WebsiteTerms,
      },
      {
        path: 'enquiry-received/',
        name: 'thankyouContact',
        component: ThankyouContact,
      },
      {
        path: 'verified-login/',
        name: 'verifiedLogin',
        component: VerifiedLogin,
      },
      {
        path: 'privacy-policy/',
        name: 'privacyPolicy',
        component: PrivacyPolicy,
      },
      {
        path: 'recover-password/',
        name: 'recoverPassword',
        component: RecoverPassword,
      },
      {
        path: 'reset-password/',
        name: 'resetPassword',
        component: ResetPassword,
      },
      {
        path: 'sustainability-policy/',
        name: 'sustainability',
        component: Sustainability,
      },
      {
        path: 'example/',
        name: 'promonorthamerica',
        component: PromoNorthAmerica,
      },
      /*
      {
        path: 'motorhome-deals/',
        name: 'promolp1',
        component: PromoLP1,
      },
      {
        path: 'sightseeing-deals/',
        name: 'promolp2',
        component: PromoLP2,
      },
      {
        path: 'covid19/',
        name: 'coronavirus',
        component: CoronaVirus,
      },
      */
      {
        path: 'the-insider/',
        name: 'blog',
        component: Blog,
      },
      {
        path: 'the-insider/category/:slug/',
        name: 'blogcategory',
        component: Blog,
      },
      {
        path: 'the-insider/:slug/',
        name: 'blogpost',
        component: BlogPost,
      },
      {
        path: 'destinations/',
        name: 'destinations',
        component: Destinations,
      },
      {
        path: 'destinations/new-zealand/',
        name: 'nzdestinations',
        component: NewZealandDestinations,
      },
      {
        path: 'destinations/new-zealand/north-island/auckland/',
        name: 'destinationAuckland',
        component: DestinationAuckland,
      },
      {
        path: 'destinations/new-zealand/north-island/hawkes-bay/',
        name: 'destinationHawkesBay',
        component: DestinationHawkesBay,
      },
      {
        path: 'destinations/new-zealand/north-island/wellington/',
        name: 'destinationWellington',
        component: DestinationWellington,
      },
      {
        path: 'destinations/new-zealand/north-island/tairawhiti-gisborne/',
        name: 'destinationTairawhitiGisborne',
        component: DestinationTairawhitiGisborne,
      },
      {
        path: 'destinations/new-zealand/north-island/taranaki/',
        name: 'destinationTaranaki',
        component: DestinationTaranaki,
      },
      {
        path: 'destinations/new-zealand/north-island/manawatu/',
        name: 'destinationManawatu',
        component: DestinationManawatu,
      },
      {
        path: 'destinations/new-zealand/north-island/wairarapa/',
        name: 'destinationWairarapa',
        component: DestinationWairarapa,
      },
      {
        path: 'destinations/new-zealand/north-island/whanganui/',
        name: 'destinationWhanganui',
        component: DestinationWhanganui,
      },
      {
        path: 'destinations/new-zealand/north-island/bay-of-plenty/',
        name: 'destinationBayOfPlenty',
        component: DestinationBayOfPlenty,
      },
      {
        path: 'destinations/new-zealand/north-island/the-coromandel/',
        name: 'destinationCoromandel',
        component: DestinationCoromandel,
      },
      {
        path: 'destinations/new-zealand/north-island/northland/',
        name: 'destinationNorthland',
        component: DestinationNorthland,
      },
      {
        path: 'destinations/new-zealand/north-island/rotorua/',
        name: 'destinationRotorua',
        component: DestinationRotorua,
      },
      {
        path: 'destinations/new-zealand/north-island/ruapehu/',
        name: 'destinationRuapehu',
        component: DestinationRuapehu,
      },
      {
        path: 'destinations/new-zealand/north-island/taupo/',
        name: 'destinationTaupo',
        component: DestinationTaupo,
      },
      {
        path: 'destinations/new-zealand/north-island/waikato/',
        name: 'destinationWaikato',
        component: DestinationWaikato,
      },
      {
        path: 'destinations/new-zealand/south-island/central-otago/',
        name: 'destinationCentralOtago',
        component: DestinationCentralOtago,
      },
      {
        path: 'destinations/new-zealand/south-island/queenstown/',
        name: 'destinationQueenstown',
        component: DestinationQueenstown,
      },
      {
        path: 'destinations/new-zealand/south-island/christchurch/',
        name: 'destinationChristchurchCanterbury',
        component: DestinationChristchurchCanterbury,
      },
      {
        path: 'destinations/new-zealand/south-island/dunedin/',
        name: 'destinationDunedin',
        component: DestinationDunedin,
      },
      {
        path: 'destinations/new-zealand/south-island/fiordland/',
        name: 'destinationFiordland',
        component: DestinationFiordland,
      },
      {
        path: 'destinations/new-zealand/south-island/nelson-tasman/',
        name: 'destinationNelsonTasman',
        component: DestinationNelsonTasman,
      },
      {
        path: 'destinations/new-zealand/south-island/southland/',
        name: 'destinationSouthland',
        component: DestinationSouthland,
      },
      {
        path: 'destinations/new-zealand/south-island/wanaka/',
        name: 'destinationWanaka',
        component: DestinationWanaka,
      },
      {
        path: 'destinations/new-zealand/south-island/marlborough/',
        name: 'destinationMarlborough',
        component: DestinationMarlborough,
      },
      {
        path: 'destinations/new-zealand/south-island/west-coast/',
        name: 'destinationWestCoast',
        component: DestinationWestCoast,
      },
      {
        path: 'destinations/new-zealand/south-island/kaikoura/',
        name: 'destinationKaikoura',
        component: DestinationKaikoura,
      },
      {
        path: 'destinations/new-zealand/south-island/hurunui/',
        name: 'destinationHurunui',
        component: DestinationHurunui,
      },
      {
        path: 'destinations/new-zealand/south-island/clutha/',
        name: 'destinationClutha',
        component: DestinationClutha,
      },
      {
        path: 'destinations/new-zealand/south-island/timaru/',
        name: 'destinationTimaru',
        component: DestinationTimaru,
      },
      {
        path: 'destinations/australia/',
        name: 'audestinations',
        component: AustraliaDestinations,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/australian-capital-territory/',
        name: 'destinationACT',
        component: DestinationACT,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/new-south-wales/',
        name: 'destinationNSW',
        component: DestinationNSW,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/northern-territory/',
        name: 'destinationNT',
        component: DestinationNT,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/queensland/',
        name: 'destinationQLD',
        component: DestinationQLD,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/south-australia/',
        name: 'destinationSA',
        component: DestinationSA,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/victoria/',
        name: 'destinationVIC',
        component: DestinationVIC,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/western-australia/',
        name: 'destinationWA',
        component: DestinationWA,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      },
      {
        path: 'destinations/australia/tasmania/',
        name: 'destinationTAS',
        component: DestinationTAS,
        meta: {
          title: 'ANZCRO | The New Zealand Holiday Experts'
        }
      }
    ],
  },
];
