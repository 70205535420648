<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <h1 class="white--text display-1 font-weight-bold mb-3">About ANZCRO</h1>
              <h3 class="grey--text title mb-4">The Australian and New Zealand Holiday Experts</h3>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="home-section-about">
      <v-flex>
        <v-card flat tile>
          <v-container text-xs-center>
            <v-layout row wrap>
              <v-flex xs12 lg10 pt-5 offset-lg1>
                <span
                  class="about-quote headline d-block font-weight-bold mb-5"
                >We love helping Travel Agents provide their customers with extraordinary holidays in Australia and New Zealand.</span>
              </v-flex>
              <v-flex xs12>
                <p
                  class="text-xs-left mx-2"
                >For over 30 years our dedicated team has lived and breathed New Zealand and Australian travel. Our vast knowledge and deep understanding of these destinations are unparalleled. We are proud to give Travel Agents the edge with our market-leading range of over 40,000 travel products from almost 5,000 New Zealand and Australian operators. When you book with ANZCRO, you can leverage the biggest selection of suppliers in one place.</p>
                <p
                  class="text-xs-left mx-2"
                >Whether your customers want to enjoy the ultimate freedom of a self-drive holiday, experience the thrill of skiing in world-class resorts, dive into the marine wonderland of the Great Barrier Reef or immerse themselves in nature on a guided tour, ANZCRO's team of specialists will ensure each itinerary is meticulously planned to cater for a variety of traveller's needs. ANZCRO also delivers you freedom in choosing how you book - either through one of our specialists or via our self-service booking platform ANZCRO Connect.</p>
                <p
                  class="text-xs-left mx-2"
                >ANZCRO's "boots-on-the-ground" approach, and state-of-the-art technology, gives you 24/7 access to our expertise and booking solution for your business. By working with us, you can rest assured your customers are in good hands.</p>
                <v-avatar :color="lightGrey" size="70" class="mt-4 mb-2">
                  <img
                    src="https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-nick.jpg"
                    alt="Nick Guthrey, CEO"
                  />
                </v-avatar>
                <span class="d-block mb-5">Nick Guthrey, CEO</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>


    <v-layout row class="about-main">
      <v-flex>
        <v-card flat tile :color="offWhite">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height class="about-intro">
              <v-flex xs6 sm3 text-xs-center>
                <img
                  height="80"
                  :src="require('@/assets/img/icons/about/about-icons-product.svg')"
                />
                <span class="display-1 coalBlack d-block mb-2">40,000+</span>
                <small class="d-block grey--text">Australian, New Zealand and<br/>South Pacific Travel Products</small>
              </v-flex>
              <v-flex xs6 sm3 text-xs-center>
                <img
                  height="80"
                  :src="require('@/assets/img/icons/about/about-operators.svg')"
                />
                <span class="display-1 coalBlack d-block mb-2">5,000</span>
                <small class="d-block grey--text">Australian, New Zealand and<br/>South Pacific Operators</small>
              </v-flex>
              <v-flex xs6 sm3 text-xs-center>
                <img
                  height="80"
                  :src="require('@/assets/img/icons/about/about-icons-training.svg')"
                />
                <span class="display-1 coalBlack d-block mb-2">45+</span>
                <small class="d-block grey--text">Destination Experts<br/>with in-depth AU, NZ and<br/>SP knowledge</small>
              </v-flex>
              <v-flex xs6 sm3 text-xs-center>
                <img
                  height="80"
                  :src="require('@/assets/img/icons/about/about-icons-famil.svg')"
                />
                <span class="display-1 coalBlack d-block mb-2">100+</span>
                <small class="d-block grey--text">Days of agent familarisation<br/>programmes annually</small>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="about-mission">
      <v-flex>
        <v-card flat tile dark :color="coalBlack">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-5 px-4 text-xs-center>
                <h3 class="home-section-title white-on-dark">
                  <span>Our Mission</span>
                </h3>
                <span
                  class="about-quote headline d-block font-weight-bold"
                >Empowering travel agents by sharing our unparalleled expertise to ensure their customers have extraordinary, great value holidays in Australia and New Zealand.</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="about-values">
      <v-flex>
        <v-card flat tile :color="lightGrey">
          <v-container grid-list-xl fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 pt-5 px-4 text-xs-center>
                <h3 class="home-section-title green-on-white">
                  <span>Our Values</span>
                </h3>
              </v-flex>
              <v-flex xs12 pb-5 px-4>
                <v-timeline class="v-timeline--values">
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Tradition</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >For over 30 years we have worked tirelessly to provide exceptional holiday experiences for customers in Australia and in New Zealand.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Knowledge</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >We have highly specialized consultants with in-depth knowledge of both Australia and New Zealand. From the first inquiry to the end of your booking, you’ll find we have the expertise to make your client holiday unforgettable. Our knowledge becomes your knowledge. To matou mōhiotanga ko to mōhiotanga.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Entrepreneurship</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >Long term vision should prevail over immediate results. Our constant growth is result of our lasting relationships with our team, agents and suppliers.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Passion</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >We love the places we sell and truly believe they are the best travel destinations in the world. New Zealand is a special and unique place with some of Nature’s most spectacular landscapes and wonders. We are also passionate about helping Aussies experience some of the most breathtaking corners of our own backyard. At ANZCRO we pride ourselves on our team of people, engaging and training passionate staff to the highest standards.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Commitment & Attention to Detail</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >Our expert team go the extra mile to ensure your clients have the best vacation experiences.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Teamwork</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >We seek great partnership with our clients, colleagues, and our community, and we are at our best when working together to produce memorable holidays.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item small>
                    <v-card class="elevation-0">
                      <v-card-title class="headline kiwiGreen pb-0">Responsiveness</v-card-title>
                      <v-card-text
                        class="pt-1"
                      >We are responsive to the diverse needs of all partners with proactive planning and reply to all requests in a timely manner.</v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="about-team">
      <v-flex>
        <v-card flat tile>
          <v-container grid-list-xl>
            <v-layout row wrap mb-5>
              <v-flex xs12 py-5 px-4 text-xs-center>
                <h3 class="home-section-title green-on-white">
                  <span>Meet the Team</span>
                </h3>
              </v-flex>
              <template v-for="(item, index) in staff">
                <v-flex
                  xs6
                  sm4
                  text-xs-center
                  :key="index"
                  py-3
                >
                  <v-avatar size="120">
                    <img height="120" :src="item.image" />
                  </v-avatar>
                  <span class="d-block subheading font-weight-medium mt-2">{{item.name}}</span>
                  <span class="d-block grey--text">{{item.position}}</span>
                  <v-btn
                        v-if="item.email"
                        fab
                        small
                        class="mx-0 elevation-0"
                        :href="'mailto:' + item.email"
                      >
                        <v-icon color="#ababab" size="20">mail</v-icon>
                  </v-btn>
                  <v-btn
                        v-if="item.linkedin_url"
                        fab
                        small
                        class="mx-0 elevation-0"
                        :href="item.linkedin_url"
                        target="_blank"
                      >
                        <img height="24" :src="require('@/assets/img/icons/social/linkedin.svg')" />
                  </v-btn>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    staff: [
      {
        name: 'Nick Guthrey',
        position: 'CEO',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-nick.jpg',
        linkedin_url: 'https://www.linkedin.com/in/nick-guthrey-99638617/',
        email: 'nick.guthrey@anzcro.com.au'
      },/*
      {
        name: 'Peter Davies',
        position: 'General Manager - NZ',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2020/01/peter-davies.jpg',
      },
      {
        name: 'Cushla Tull',
        position: 'Contact Centre Manager',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/cushla-tull.jpg',
      },*/
      {
        name: 'Brett Goodwin',
        position: 'CFO',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-brett.jpg',
        linkedin_url: 'https://www.linkedin.com/in/brett-goodwin-87b831154/',
        email: 'brett@anzcro.com.au'
      },
      {
        name: 'Louise Bilbie',
        position: 'Head of Product',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-louise.jpg',
        linkedin_url: 'https://www.linkedin.com/in/louise-bilbie-81795112/',
        email: 'louise.bilbie@anzcro.com.au'
      },
      {
        name: 'Kiran Nambiar',
        position: 'Snr VP of Sales, North America',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-kiran.jpg',
        linkedin_url: 'https://www.linkedin.com/in/kiran-nambiar-nz/',
        email: 'kiran@anzcro.com'
      },
      {
        name: 'Amy Mace',
        position: 'Head of Marketing',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-amy.jpg',
        linkedin_url: 'https://www.linkedin.com/in/amy-mace-35490411/',
        email: 'amy.mace@anzcro.com.au'
      },
      {
        name: 'Cushla Tull',
        position: 'Customer Service Manager',
        image: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/anzcro-staff-cushla.jpg',
        linkedin_url: 'https://www.linkedin.com/in/cushla-tull-5a92a2174/',
        email: 'cushla.tull@anzcro.com.au'
      },/*
      {
        name: 'Tony Saunders',
        position: 'Sales & Marketing Manager',
        image:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/tony-saunders-temp.jpg',
      },*/
    ],
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.about-hero {
  height: 70vh;
}

.about-hero .v-image__image {
  background-attachment: fixed;
}

.about-intro {
  margin-top: 0 !important;
  margin-bottom: 36px !important;
}

.hero-title h1,
.hero-title h3 {
  line-height: 1.2 !important;
}

.about-mission {
  background-color: #30302f;
  background-image: url('~@/assets/img/bg_tile_grey_waves_2.png');
  background-repeat: repeat;
  background-size: 50px 51px;
}

.about-mission .v-card {
  background-color: transparent !important;
}

@media (max-width: 599px) {
  .v-timeline--values:before {
    left: 18px;
  }
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right),
  .v-timeline--dense .v-timeline-item--left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .v-timeline--values .v-timeline-item__dot--small {
    left: 7px;
  }
  .v-timeline--values .v-timeline-item__body {
    max-width: calc(100% - 64px);
  }
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:before,
  .v-timeline--values .v-timeline-item--left .v-card:before,
  .v-timeline--values .v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:after,
  .v-timeline--values .v-timeline-item--left .v-card:after {
    right: initial;
    left: -10px;
    -webkit-transform: none;
    transform: none;
  }
}

@media (min-width: 600px) {
  .about-hero {
    height: 60vh;
  }
  .hero-title .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }
  .about-intro p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .v-timeline--values .v-timeline-item {
    padding-bottom: 0;
    margin-bottom: -24px;
  }
  .v-timeline--values .v-timeline-item:last-of-type {
    margin-bottom: 0;
  }
}

@media (min-width: 960px) {
}
</style>
