<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
                <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-deals-lake-matheson-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Itineraries</h1>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md12 py-0 my-0>
                          <span class="headline font-weight-medium kiwiGreen d-block mb-3">Travel Inspiration at your fingertips.</span>
                          <p>Let ANZCRO’s 30 years of experience meticulously planning holidays Down Under help guide your vacation planning. From iconic landmarks to hidden gems, our curated self-guided tours offer accommodation and sightseeing suggestions based on our extensive selection of over 40,000 Australian and New Zealand travel products. All packages are fully customizable to accommodate alternative luxury and boutique accommodations, private touring, and once-in-a-lifetime sightseeing experiences.</p>
                          <p class="font-weight-medium">For a fully customized itinerary from start to finish, please <a href="/contact/">contact us</a>.</p>
                        </v-flex>
                        <!--<v-flex xs12 md2 py-0 my-0 text-xs-center hidden-sm-and-down>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/anzcro-snow-dough-2022-192x190-v2.png" alt="Anzcro’s Snow Dough 2022" class="campaign-sticker kirra-early-bird" width="96" height="95" />
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><b>SELL SNOW FOR YOUR CHANCE TO WIN A $1,000 TRAVEL CREDIT</b></small>
                        </v-flex>-->
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index" :id="section.section_id">
                    <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile :color="kiwiGreen" class="pa-0 ma-0 elevation-0" v-if="section.section_id == 'New-Zealand-From-Chistchurch'">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="d-block font-weight-medium white--text"
                      >Take advantage of the new direct route <b>San Francisco</b>—<b>Christchurch</b>.</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile color="#ececec" class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="d-block font-weight-medium kiwiGreen"
                      >All itineraries can be customised to suit guest’s trip duration, accommodation standard and touring style.</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price ({{section.currency}})</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">View Details (PDF)</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="d-block itinerary-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.start_location}}</span>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>End:</b>&nbsp;<span class="package-code">{{deal.end_location}}</span>
                                  </v-chip>
                                </span>
                                <span class="d-block itinerary-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Highlights:</b>&nbsp;<span class="package-start-location">{{deal.highlights}}</span>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <!--<v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>-->
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Details (PDF)</span>
                          </v-tooltip>
                        </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Australian Itineraries',
          section_id : 'Australian-Itineraries',
          currency : 'AUD',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/city-rock-reef.jpg',
              name : '12 Day City, Rock & Reef',
              price : '6219',
              complement : 'per person (twin share)',
              start_location : 'Sydney, NSW',
              end_location : 'Sydney, NSW',
              highlights: 'Sydney Opera House, Blue Mountains, Red Centre, Great Barrier Reef',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/12-Day-City-Rock-Reef-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/west-to-east.jpg',
              name : '16 Day West to East Discovery',
              price : '8085',
              complement : 'per person (twin share)',
              start_location : 'Perth, WA',
              end_location : 'Cairns, QLD',
              highlights: 'Rottnest Island, Great Ocean Road, Sydney, Great Barrier Reef',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/16-Day-West-to-East-Discovery-AUD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/australian-odyssey.jpg',
              name : '19 Day Australian Odyssey',
              price : '8190',
              complement : 'per person (twin share)',
              start_location : 'Melbourne, VIC',
              end_location : 'Sydney, NSW',
              highlights: 'Great Ocean Road, Kangaroo Island, Barossa, Ghan Rail Journey, Red Centre',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/19-Day-Australian-Odyssey-AUD-Flyer.pdf'
            },
          ]
        },
        {
          title : 'New Zealand Itineraries - From Auckland',
          section_id : 'New-Zealand-From-Auckland',
          currency : 'NZD',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/south-island-adventure.jpg',
              name : '15 Day South Island Adventure',
              price : '6779',
              complement : 'per person (twin share)',
              start_location : 'Auckland, North Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Milford Sound, Abel Tasman, Kaikoura Whale Watch, Tasman Glacier',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/15-Day-South-Island-Adventure-NZD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/pacific-escapade.jpg',
              name : '18 Day Pacific Escapade',
              price : '9495',
              complement : 'per person (twin share)',
              start_location : 'Auckland, North Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Bay of Islands, Mt Cook National Park, Fiordland and West Coast Glaciers',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/18-Day-Pacific-Escapade-NZD-Flyer.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/bes-of-both.jpg',
              name : '22 Day Best of Both Islands',
              price : '8619',
              complement : 'per person (twin share)',
              start_location : 'Auckland, North Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Bay of Islands Scenic Flight, Mt Cook National Park, Fiordland and Rotorua',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/22-Day-Best-of-Both-Islands-NZD-Flyer.pdf'
            },
          ]
        },
        {
          title : 'New Zealand Itineraries - From Christchurch',
          section_id : 'New-Zealand-From-Chistchurch',
          currency : 'USD',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/mountain-vistas.jpg',
              name : '7 Day Mountain Vistas',
              price : '3349',
              complement : 'per person (twin share)',
              start_location : 'Christchurch, South Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Milford Sound Cruise & Scenic Flight, Glacier Explorer Boat Trip, Walter Peak Gourmet BBQ',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/6-Night-Mountain-Vistas-Retail-USD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/south-island-voyage.jpg',
              name : '12 Day South Island Voyage',
              price : '4139',
              complement : 'per person (twin share)',
              start_location : 'Christchurch, South Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Milford Sound, Queenstown, Mt Cook N.P., West Coast Glaciers, TranzAlpine Train',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/11-Night-South-Island-Voyage-Retail-USD.pdf'
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/05/complete-south-island.jpg',
              name : '18 Day Complete South Island',
              price : '6099',
              complement : 'per person (twin share)',
              start_location : 'Christchurch, South Island',
              end_location : 'Christchurch, South Island',
              highlights: 'Scenic Flight, 4 Scenic Cruises, Whale Watch Tour, Coastal Pacific Train Journey',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/06/17-Night-Complete-South-Island-Retail-USD.pdf'
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .itinerary-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  