const Dashboard = () => import('@/components/dashboard/Dashboard.vue');
const DashboardHome = () => import('@/components/dashboard/DashboardHome.vue');
const DashboardQuotes = () => import('@/components/dashboard/DashboardQuotes.vue');
const DashboardBookings = () => import('@/components/dashboard/DashboardBookings.vue');
const DashboardResources = () => import('@/components/dashboard/resources/DashboardResources.COM.vue');
//const DashboardRewards = () => import('@/components/dashboard/DashboardRewards.vue');
const DashboardNotifications = () => import('@/components/dashboard/DashboardNotifications.vue');
const DashboardAccount = () => import('@/components/dashboard/account/DashboardAccount.vue');
const DashboardPreferences = () => import('@/components/dashboard/DashboardPreferences.vue');
const DashboardAgents = () => import('@/components/dashboard/agents/DashboardAgents.vue');
const DashboardRequests = () => import('@/components/dashboard/requests/DashboardRequests.vue');
const DashboardQuote = () => import('@/components/dashboard/DashboardQuote.vue');
const DashboardBooking = () => import('@/components/dashboard/DashboardBooking.vue');
const DashboardHelp = () => import('@/components/dashboard/help/DashboardHelp.vue');
const DashboardHelpGuideContent = () =>
  import('@/components/dashboard/help/DashboardHelpGuideContent.vue');
const DashboardHelpGuide = () => import('@/components/dashboard/help/DashboardHelpGuide.vue');
const DashboardHelpVideos = () => import('@/components/dashboard/videos/DashboardHelpVideos.vue');
const DashboardVideoGettingStarted = () => import('@/components/dashboard/videos/VideoGettingStarted.vue');
const DashboardVideoResources = () => import('@/components/dashboard/videos/VideoResources.vue');
const DashboardBrochures = () => import('@/components/dashboard/resources/DashboardBrochures.vue');
const DashboardPrintMedia = () => import('@/components/dashboard/resources/DashboardPrintMedia.vue');
const DashboardMaps = () => import('@/components/dashboard/resources/DashboardMaps.vue');
//const DashboardBrand = () => import('@/components/dashboard/resources/DashboardBrand.vue');
const DashboardDigitalMedia = () => import('@/components/dashboard/resources/DashboardDigitalMedia.vue');
const DashboardNZInfo = () => import('@/components/dashboard/resources/DashboardNZInfo.vue');
const DashboardExperiences = () => import('@/components/dashboard/DashboardExperiences.vue');
const DashboardExpCoach = () => import('@/components/dashboard/DashboardExpCoach.vue');
const DashboardExpCruiseShore = () => import('@/components/dashboard/DashboardExpCruiseShore.vue');
const DashboardExpCruiseStay = () => import('@/components/dashboard/DashboardExpCruiseStay.vue');
const DashboardExpWalks = () => import('@/components/dashboard/DashboardExpWalks.vue');
const DashboardExpRail = () => import('@/components/dashboard/DashboardExpRail.vue');
const DashboardExpSki = () => import('@/components/dashboard/DashboardExpSki.vue');
const DashboardExpLuxuryAccomm = () =>
  import('@/components/dashboard/DashboardExpLuxuryAccomm.vue');

const ItineraryBuilderSection = () =>
  import('@/components/dashboard/help/ItineraryBuilderSection.vue');
const DashboardSection = () => import('@/components/dashboard/help/DashboardSection.vue');

const DashboardPayments = () => import('@/components/dashboard/DashboardPayments.vue');

export const dashboardRoutes = [
  {
    path: '/dashboard/',
    component: Dashboard,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'home/',
        name: 'dashboardhome',
        component: DashboardHome,
      },
      {
        path: 'quotes/',
        name: 'dashboardquotes',
        component: DashboardQuotes,
      },
      {
        path: 'quote/:id',
        name: 'dashboard-quote',
        component: DashboardQuote,
      },
      {
        path: 'bookings/',
        name: 'dashboardbookings',
        component: DashboardBookings,
      },
      {
        path: 'booking/:booking',
        name: 'dashboardbooking',
        component: DashboardBooking,
      },
      {
        path: 'resources/',
        name: 'dashboardresources',
        component: DashboardResources,
      },
      {
        path: 'experiences/',
        name: 'dashboardexperiences',
        component: DashboardExperiences,
      },
      {
        path: 'experiences/coach-tours/',
        name: 'dashboardexperiencescoach',
        component: DashboardExpCoach,
      },
      {
        path: 'experiences/cruise-shore-excursions/',
        name: 'dashboardexperiencescruiseshore',
        component: DashboardExpCruiseShore,
      },
      {
        path: 'experiences/cruise-and-stay/',
        name: 'dashboardexperiencescruisestay',
        component: DashboardExpCruiseStay,
      },
      {
        path: 'experiences/rail-journeys/',
        name: 'dashboardexperiencesrail',
        component: DashboardExpRail,
      },
      {
        path: 'experiences/walks/',
        name: 'dashboardexperienceswalks',
        component: DashboardExpWalks,
      },
      {
        path: 'experiences/ski-holidays/',
        name: 'dashboardexperiencesski',
        component: DashboardExpSki,
      },
      {
        path: 'experiences/luxury-hotels/',
        name: 'dashboardexperiencesluxury',
        component: DashboardExpLuxuryAccomm,
      },
      /*
      {
        path: 'rewards/',
        name: 'dashboardrewards',
        component: DashboardRewards,
      },
      */
      {
        path: 'notifications/',
        name: 'dashboardnotifications',
        component: DashboardNotifications,
      },
      {
        path: 'account/',
        name: 'dashboardaccount',
        component: DashboardAccount,
      },
      {
        path: 'preferences/',
        name: 'dashboardpreferences',
        component: DashboardPreferences,
      },
      {
        path: 'agents/',
        name: 'dashboardagents',
        component: DashboardAgents,
      },
      {
        path: 'requests/',
        name: 'dashboardrequests',
        component: DashboardRequests,
      },
      {
        path: 'help/',
        name: 'dashboardhelp',
        component: DashboardHelp,
      },
      {
        path: 'help/guide/',
        name: 'dashboardhelpguide',
        component: DashboardHelpGuide,
        children: [
          {
            path: '',
            component: DashboardHelpGuideContent,
          },
          {
            path: 'itinerary-builder/:section/:child/',
            component: ItineraryBuilderSection,
          },
          {
            path: ':section/:child/',
            component: DashboardSection,
          },
        ],
      },
      {
        path: 'help/videos/',
        name: 'dashboardhelpvideos',
        component: DashboardHelpVideos,
      },
      {
        path: 'help/videos/getting-started/',
        name: 'dashboardhelpvideogetstarted',
        component: DashboardVideoGettingStarted,
      },
      {
        path: 'help/videos/resources/',
        name: 'dashboardhelpvideoresources',
        component: DashboardVideoResources,
      },
      {
        path: 'resources/brochures/',
        name: 'dashboardbrochures',
        component: DashboardBrochures,
      },
      {
        path: 'resources/print-media-assets/',
        name: 'dashboardprintmedia',
        component: DashboardPrintMedia,
      },
      /*
      {
        path: 'resources/brand/',
        name: 'dashboardbrand',
        component: DashboardBrand,
      },*/
      {
        path: 'resources/maps/',
        name: 'dashboardmaps',
        component: DashboardMaps,
      },
      {
        path: 'resources/digital-media-assets/',
        name: 'dashboarddigitalmedia',
        component: DashboardDigitalMedia,
      },
      {
        path: 'resources/new-zealand-info/',
        name: 'dashboardnzinfo',
        component: DashboardNZInfo,
      },
      {
        path: 'payments/result/',
        name: 'paymentResult',
        component: DashboardPayments
      }
    ],
  },
];
