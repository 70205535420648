import { render, staticRenderFns } from "./Contact.COM.vue?vue&type=template&id=7c0cd044"
import script from "./Contact.COM.vue?vue&type=script&lang=js"
export * from "./Contact.COM.vue?vue&type=script&lang=js"
import style0 from "./Contact.COM.vue?vue&type=style&index=0&id=7c0cd044&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VExpansionPanel } from 'vuetify/lib'
import { VExpansionPanelContent } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {
  VBtn,
  VCard,
  VCardText,
  VContainer,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VFlex,
  VForm,
  VIcon,
  VLayout,
  VTextField,
  VTextarea,
})
