<template>
    <v-container pa-0 fluid>
        <v-layout row>
            <v-flex>
                <v-card flat tile dark class="hero-home-slider">
                    <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
                        <v-carousel-item>
                            <v-img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/helicopter-line-hero-scaled.jpg"
                                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.15) 65%"
                                class="home-hero">
                                <v-container fill-height>
                                    <v-layout dark column fill-height justify-space-between pt-5>
                                        <v-flex text-xs-center class="home-hero-cta">
                                            <v-layout row align-center fill-height class="fill-height-fix-ios">
                                                <v-flex>
                                                    <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/ultimate-nz-vacation_header-graphic_1280x1280.png"
                                                        alt="New Zealand Winter Holiday Collection"
                                                        class="title-graphic" width="480" height="480" />
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                <div class="hero-caption hidden-xs-only">
                                    <small>Stunning panoramic views over Queenstown and Lake Whakatipu</small>
                                </div>
                            </v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-card>
            </v-flex>
        </v-layout>
        <div class="gradient-bar"></div>

        <v-layout row>
            <v-flex mb-3>
                <v-container grid-list-lg fill-height>
                    <v-layout row wrap fill-height>
                        <v-flex xs12 py-0>
                            <v-card tile class="pa-0 elevation-0 deals-content">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 md9 py-0 my-0>
                                            <h2>Register for a chance to win the Ultimate 12-night New Zealand Vacation
                                                Prize Package for two valued at US$ 18,000. </h2>
                                            <p class="subheading mt-3">Including international airfare in premium
                                                economy class, 12 nights’ accommodation in 4- and 5-star hotels,
                                                internal flights, local transport, sightseeing, activities, attractions,
                                                and some meals.</p>
                                        </v-flex>
                                        <v-flex xs12 md3 py-0 my-0 text-xs-center>
                                            <v-btn block large class="ml-0 x-large elevation-0" dark :color="kiwiGreen"
                                                :href="form_url" disabled>Register Now</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                            <v-card tile class="pa-0 elevation-0 mt-5">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 py-0 my-0>
                                            <h2>ANZCRO, in partnership with Air New Zealand and New Zealand travel
                                                product
                                                suppliers, offer you a chance to win the Ultimate New Zealand Vacation
                                                Prize
                                                Package for two!</h2>
                                            <p class="subheading mt-3">Calling all Travel Advisors in North America! Are
                                                you ready to experience the magic of New Zealand firsthand (and sell it
                                                like a pro)? This exclusive promotion is your chance to win a dream
                                                vacation packed with adventure, luxury, and local flavour – open to all
                                                full-time or part-time Travel Advisors based in North America who are
                                                already selling or are interested to start selling New Zealand
                                                vacations. </p>
                                            <p class="subheading mt-3">Enjoy international flights in Premium Economy
                                                Class from Air New Zealand’s online ports in North America to Auckland,
                                                as well as internal domestic flights in New Zealand to seamlessly
                                                traverse from the North Island to the South Island. Thanks to our
                                                esteemed partners at ANZCRO, luxuriate in 12-nights of accommodation at
                                                4- and 5- star hotels, with transport, sightseeing, attractions, and
                                                meals as specified in the inclusions. Embark on thrilling adventures and
                                                immerse yourself in unforgettable experiences – from nature excursions
                                                to wine tours, and even adrenaline-pumping activities like skydiving,
                                                bungee jumping, jet boat rides, and much more! </p>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex>
                <v-card flat color="#f4f4f4">
                    <v-container text-xs-center>
                        <v-layout row>
                            <v-flex xs10 py-0 offset-xs1>
                                <h3 class="home-section-title green-on-white">
                                    <span>Powered by</span>
                                </h3>
                                <v-layout row wrap>
                                    <v-flex xs12 sm4 offset-sm2>
                                        <img width="280" class="mx-auto max-width-100"
                                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/air-new-zealand-logo.svg" />
                                    </v-flex>
                                    <v-flex xs12 sm4>
                                        <img width="280" class="mx-auto max-width-100"
                                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/anzcro-logo.svg" />
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex mb-3>
                <v-container grid-list-lg fill-height>
                    <v-layout row wrap fill-height>
                        <v-flex xs12 py-0 my-0>
                            <v-card tile class="pa-0 elevation-0">
                                <v-card-text class="pa-5">
                                    <v-layout row wrap align-center>
                                        <v-flex xs12 py-0 my-0>
                                            <v-carousel hide-delimiters height="auto" class="elevation-0">
                                                <v-carousel-item v-for="(item, index) in gallery_items" :key="index">
                                                    <v-img :src="item.src">
                                                        <v-container fill-height>
                                                            <v-layout dark fill-height justify-end align-end>
                                                                <v-flex text-xs-center text-sm-right><span
                                                                        class="d-inline-block py-1 px-2 black white--text caption">{{
                                                                        item.caption }}</span></v-flex>
                                                            </v-layout>
                                                        </v-container>
                                                    </v-img>
                                                </v-carousel-item>
                                            </v-carousel>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap align-center>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0 mt-3">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>Ultimate New Zealand Prize Package Inclusions</h2>
                                                            <span class="kiwiGreen">Valued at approx. US$18,000</span>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 py-4 px-3" color="#fafafa">
                                                        <ul>
                                                            <li>International return airfares on Premium Economy Class
                                                                from
                                                                online ports of Air New Zealand in North America to
                                                                Auckland.</li>
                                                            <li>New Zealand internal domestic return airfares for
                                                                inter-island flights. </li>
                                                            <li>2-nights’ accommodation at Sudima Hotel, Auckland City.
                                                            </li>
                                                            <li>2-nights’ accommodation at Sudima Hotel, Rotorua.</li>
                                                            <li>2-nights’ accommodation at Sudima Hotel, Christchurch
                                                                City.
                                                            </li>
                                                            <li>2-nights’ accommodation at The Hermitage, Mount Cook.
                                                            </li>
                                                            <li>4-nights’ accommodation at The Rees, Queenstown.</li>
                                                            <li>All Blacks Experience, Auckland. </li>
                                                            <li>WETA Unleashed Studio, Auckland. </li>
                                                            <li>Auckland Explorer Bus, Auckland.</li>
                                                            <li>Auckland Whale & Dolphin Tour, Auckland. </li>
                                                            <li>Great Sights Tour from Auckland to Rotorua, including a
                                                                stop
                                                                at Hobbiton Movie Set Tour. </li>
                                                            <li>Agrodome Farm Show, Rotorua. </li>
                                                            <li>National Kiwi Hatchery, Rotorua. </li>
                                                            <li>Wai-o-Tapu Geothermal Park, Rotorua. </li>
                                                            <li>Te Puia Geothermal Park with Maori cultural performance
                                                                and
                                                                dinner. </li>
                                                            <li>Wai-a-Riki Spa restorative journey, Rotorua. </li>
                                                            <li>International Antarctic Centre, Christchurch.</li>
                                                            <li>Great Sights Tour from Christchurch to Mount Cook.</li>
                                                            <li>Glacier Explorers glacial lake boat trip, Mount Cook.
                                                            </li>
                                                            <li>Great Sights Tour from Mount Cook to Queenstown. </li>
                                                            <li>A. J. Hackett Kawarau Bridge Bungy Jump, Queenstown.
                                                            </li>
                                                            <li>12,000 feet NZONE Skydive, Queenstown. </li>
                                                            <li>The Helicopter Line Pilot’s Choice scenic flight,
                                                                Queenstown. </li>
                                                            <li>Milford Sound Scenic Flights Day trip to Milford Sound
                                                                including Milford Sound Cruise. </li>
                                                            <li>Real NZ Day-trip to Doubtful Sound.</li>
                                                            <li>Half Day Dart River Adventures Jetboat safari,
                                                                Queenstown.
                                                            </li>
                                                            <li>TSS Earnslaw Cruise to Walter Peak Famr including BBQ
                                                                dinner, Queenstown. </li>
                                                            <li>Nomad Safaris Lord of The Rings 4-wheel drive tour,
                                                                Queenstown. </li>
                                                            <li>Skyline Queenstown Gondola and Luge Ride, Queenstown.
                                                            </li>
                                                            <li>Altitude Tours half day wine sampler tour, Queenstown.
                                                            </li>
                                                        </ul>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-divider class=""></v-divider>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>How to Win</h2>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 py-4 px-3" color="#fafafa">
                                                        <ul>
                                                            <li>Read the competition terms and conditions.</li>
                                                            <li>Fill your details and submit an entry at the <a
                                                                    :href="form_url" target="_blank">competition
                                                                    registration page</a>.</li>
                                                            <li>By submitting an entry into the competition, you state
                                                                that you agree with the competition terms
                                                                and conditions.</li>
                                                            <li>Be selected as the winner in a prize draw conducted by
                                                                ANZCRO in August 2024.</li>
                                                        </ul>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-divider class=""></v-divider>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-expansion-panel class="elevation-0">
                                                <v-expansion-panel-content>
                                                    <template v-slot:header>
                                                        <div>
                                                            <h2>Terms and conditions</h2>
                                                        </div>
                                                    </template>
                                                    <v-card class="elevation-0 py-4 px-3" color="#fafafa">
                                                        <p>Anzcro (NZBN 9429037278687) situated Level 3, 57 Shotover
                                                            Street, Queenstown, New Zealand, hereafter
                                                            referred to as (“the Promoter”) will be running the Anzcro
                                                            “NZ Prize Package Draw” Promotion (“the
                                                            Promotion”). Commencing 0001hrs NZT on 1 April 2024 and
                                                            closes at 2359hrs NZT on 31 July 2024
                                                            (Promotion Period).</p>

                                                        <ul>
                                                            <li>You are eligible to enter if you submit a valid entry
                                                                and agree to the Terms and Conditions.
                                                            </li>
                                                            <li>The only way to submit a valid entry is by the
                                                                following:
                                                                <ul class="my-3">
                                                                    <li>Registering for the competition at
                                                                        <a
                                                                            href="https://www.anzcro.com/win/">www.anzcro.com/win</a>
                                                                    </li>
                                                                    <li>And completing all fields with valid details;
                                                                    </li>
                                                                    <li>By reading and agreeing to the Terms and
                                                                        Conditions.</li>
                                                                </ul>
                                                            </li>
                                                            <li>Entry is open to full time and part time Travel Advisors
                                                                based in North America (United States,
                                                                Canada and Mexico) selling Vacation packages. All
                                                                applicants have to be over the age of 18
                                                                (Eligible Entrants). </li>
                                                            <li>The winners will be notified in August 2024 via email.
                                                            </li>
                                                            <li>The NZ Prize Package winner will be picked in a draw at
                                                                the Anzcro office in Queenstown, New
                                                                Zealand. </li>
                                                            <li>There will be one winner with NZ Prize Package content
                                                                for two people (winner plus companion).
                                                            </li>
                                                            <li>The prize on offer:12 Night Iconic New Zealand Vacation
                                                                Prize Package for two with international
                                                                and domestic flights for travel in May and/or June 2025.
                                                                Approx Value: US$ 18,000 (approximately
                                                                NZ$ 28,000).</li>
                                                        </ul>

                                                        <p class="font-weight-medium my-3">Includes:</p>

                                                        <ul>
                                                            <li>2 x Return Premium Economy Class International Ticket on
                                                                Air New Zealand from one of the 6x
                                                                online airports in North America to Auckland (flight
                                                                confirmation airport and date subject to
                                                                availability).</li>
                                                            <li>2 x Economy Class domestic New Zealand travel sectors
                                                                from Rotorua to Christchurch; and from
                                                                Queenstown to Auckland (flight confirmation and date
                                                                subject to availability).</li>
                                                            <li>Accommodation in a Twin or Double Room in the following
                                                                cities/towns in New Zealand (room
                                                                confirmation dates subject to availability):
                                                                <ul class="my-3">
                                                                    <li>2 nights at Sudima Auckland City Hotel.</li>
                                                                    <li>2 nights at Sudima Rotorua Hotel. </li>
                                                                    <li>2 nights at Sudima Christchurch City Hotel.
                                                                    </li>
                                                                    <li>2 nights at The Hermitage Hotel, Mount Cook
                                                                        Village. </li>
                                                                    <li>4 nights at The Rees Hotel, Queenstown. </li>
                                                                </ul>
                                                            </li>
                                                            <li>Transport, sightseeing, activities, attractions and
                                                                meals for 2 people (winner plus companion)
                                                                as per the following:
                                                                <ul class="my-3">
                                                                    <li>All Blacks Experience, Auckland. </li>
                                                                    <li>WETA Unleashed Studio, Auckland. </li>
                                                                    <li>Auckland Explorer Bus, Auckland.</li>
                                                                    <li>Auckland Whale & Dolphin Tour, Auckland. </li>
                                                                    <li>Great Sights Tour from Auckland to Rotorua,
                                                                        including a stop at Hobbiton Movie Set Tour.
                                                                    </li>
                                                                    <li>Agrodome Farm Show, Rotorua. </li>
                                                                    <li>National Kiwi Hatchery, Rotorua. </li>
                                                                    <li>Wai-o-Tapu Geothermal Park, Rotorua. </li>
                                                                    <li>Te Puia Geothermal Park with Maori cultural
                                                                        performance and dinner. </li>
                                                                    <li>Wai-a-Riki Spa restorative journey, Rotorua.
                                                                    </li>
                                                                    <li>International Antarctic Centre, Christchurch.
                                                                    </li>
                                                                    <li>Great Sights Tour from Christchurch to Mount
                                                                        Cook.</li>
                                                                    <li>Glacier Explorers glacial lake boat trip, Mount
                                                                        Cook. </li>
                                                                    <li>Great Sights Tour from Mount Cook to Queenstown.
                                                                    </li>
                                                                    <li>A. J. Hackett Kawarau Bridge Bungy Jump,
                                                                        Queenstown. </li>
                                                                    <li>12,000 feet NZONE Skydive, Queenstown. </li>
                                                                    <li>The Helicopter Line Pilot’s Choice scenic
                                                                        flight, Queenstown. </li>
                                                                    <li>Milford Sound Scenic Flights Day trip to Milford
                                                                        Sound including Milford Sound Cruise.
                                                                    </li>
                                                                    <li>Real NZ Day-trip to Doubtful Sound.</li>
                                                                    <li>Half Day Dart River Adventures Jetboat safari,
                                                                        Queenstown. </li>
                                                                    <li>TSS Earnslaw Cruise to Walter Peak Famr
                                                                        including BBQ dinner, Queenstown. </li>
                                                                    <li>Nomad Safaris Lord of The Rings 4-wheel drive
                                                                        tour, Queenstown. </li>
                                                                    <li>Skyline Queenstown Gondola and Luge Ride,
                                                                        Queenstown. </li>
                                                                    <li>Altitude Tours half day wine sampler tour,
                                                                        Queenstown. </li>
                                                                </ul>
                                                            </li>
                                                            <li>In order to be eligible to win, entrants must be
                                                                eligible to travel to New Zealand and have a
                                                                valid passport.</li>
                                                            <li>The prize does not included travel insurance; any
                                                                additional meals, sightseeing, transport,
                                                                activities, attractons that is not mentioned as included
                                                                above. </li>
                                                            <li>All ticket taxes, airport and government costs are at
                                                                the traveller’s expense. </li>
                                                            <li>The winners will pay for applicable taxes & surcharges.
                                                            </li>
                                                            <li>The winner is responsible for any tax implications or
                                                                regulations for prize draw winners in
                                                                their country of residence. </li>
                                                            <li>The winners are responsible for any Covid related
                                                                expenses, such as, but not limited to PCR
                                                                testing.</li>
                                                            <li>The winners will be responsible for obtaining their own
                                                                travel insurance prior to departure from
                                                                North America.</li>
                                                            <li>The prize is not exchangeable or redeemable for cash or
                                                                other goods or services.</li>
                                                            <li>The prize cannot be transferred to any other person,
                                                                unless agreed to by the Promoter. It is a
                                                                condition of accepting a prize that the winners accept
                                                                the conditions of use of that prize.</li>
                                                            <li>The Winner might have to select the final activities,
                                                                attractions, sightseeing to be included
                                                                from within all inclusions depending on the time in each
                                                                location. </li>
                                                            <li>The Winner is encouraged to finalise the exact travel
                                                                dates well in advance to ensure
                                                                availability of all travel products included in the
                                                                Promotion. </li>
                                                            <li>By entering the Promotion you are indicating your
                                                                consent to having your name and photo include
                                                                in a winner announcement on social channels and media in
                                                                North America, Australia and New
                                                                Zealand.</li>
                                                            <li>By entering the Promotion you are indicating your
                                                                consent to pass on your contact details to
                                                                suppliers and operators that have supported and
                                                                contributed to this NZ prize package.</li>
                                                            <li>By entering the competition you are indicating your
                                                                consent to receiving Australia & New Zealand
                                                                information e-newsletter from ANZCRO and supplier
                                                                partners associated with this NZ Prize
                                                                package. You can unsubscribe at any time.</li>
                                                            <li>Winner must be able to travel in May and/or June 2025.
                                                            </li>
                                                        </ul>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-layout row>
            <v-flex mb-5>
                <v-card flat color="#f4f4f4">
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs10 offset-xs1>
                                <v-btn block large class="x-large elevation-0 mt-3 mb-5" dark :color="kiwiGreen"
                                    :href="form_url" disabled>Register
                                    Now</v-btn>
                            </v-flex>
                            <v-flex xs12>
                                <h3 class="home-section-title green-on-white text-xs-center mt-2">
                                    <span>Why ANZCRO</span>
                                </h3>
                                <v-layout row wrap>
                                    <v-flex xs12 md6 lg4>
                                        <v-card flat class="pa-4 mx-1 mb-4 pl-5" color="#fff">
                                            <v-responsive :aspect-ratio="2 / 1">
                                                <p class="subheading mt-2"><span class="title">Over 30 years of
                                                        experience</span>
                                                    <br />in providing extraordinary vacations within Australia and New
                                                    Zealand.
                                                </p>
                                            </v-responsive>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs12 md6 lg4>
                                        <v-card flat class="pa-4 mx-1 mb-4 pl-5" color="#fff">
                                            <v-responsive :aspect-ratio="2/1">
                                                <p class="subheading mt-2"><span class="title">Market-leading
                                                        range</span>
                                                    <br />of
                                                    over 40,000 travel products from 5,000 New Zealand and Australian
                                                    operators.
                                                </p>
                                            </v-responsive>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs12 md6 lg4>
                                        <v-card flat class="pa-4 mx-1 mb-4 pl-5" color="#fff">
                                            <v-responsive :aspect-ratio="2/1">
                                                <p class="subheading mt-2"><span class="title">Choose how you book
                                                        –</span>
                                                    <br />either through one of our specialists or via our self-service
                                                    booking
                                                    platform ANZCRO Connect.
                                                </p>
                                            </v-responsive>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm10 offset-sm1>
                                        <v-card flat class="pa-0 mx-1 mt-5 mb-4 px-2" color="transparent">
                                            <p class="subheading mb-0 text-xs-center">For questions about this competition or to learn
                                                more about working with ANZCRO as an Australian and New Zealand DMC,
                                                please contact <a href="mailto:kiran@anzcro.com">kiran@anzcro.com</a> or
                                                <a href="mailto:anzcro@anzcro.com">anzcro@anzcro.com</a>.</p>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>
<script>
export default {
    data: () => ({
        gallery_items: [
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_all-blacks-experience-auckland.jpg',
                caption: 'All Blacks Experience, Auckland'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/promo-gallery_pohutu-geyser-rotorua.jpg',
                caption: 'Pohutu Geyser, Whakarewarewa Thermal Valley, Rotorua'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_te-puia.jpg',
                caption: 'Maori cultural performance at Te Puia, Rotorua'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_glacier-explorer.jpg',
                caption: 'Glacial Lake Boat Trip, Mount Cook'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_the-rees-queenstown.jpg',
                caption: 'The Rees, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_tss-earnslaw.jpg',
                caption: 'TSS Earnslaw Cruise, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_altitude-wine-sampler.jpg',
                caption: 'Half-day Wine Sampler tour, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_helicopter-line-queenstown.jpg',
                caption: 'The Helicopter Line Pilot\'s Choice Scenic Flight, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_dart-river.jpg',
                caption: 'Dart River Adventures Jetboat safari, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/04/promo-gallery_kawarau-bridge-bungy.jpg',
                caption: 'Kawarau Bridge Bungy Jump, Queenstown'
            },
            {
                src: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/promo-gallery_realnz-doubtful.jpg',
                caption: 'Doubtful Sound, Fiordland'
            },
        ],
        form_url: '#',
    }),
    mounted: function () {
        const el = document.scrollingElement || document.documentElement;
        el.scrollTop = 0;
    },
    methods: {
        top() {
            document.documentElement.scrollTop = 0;
        },
    },
};
</script>

<style>
.gradient-bar {
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear, left top, right top, from(#65b32e), color-stop(#d2d2d2), to(#30302f));
    background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
    margin-top: -60px;
}

.package-data .v-chip {
    color: #9e9e9e;
    margin-left: 0;
}

.package-data .v-chip .v-chip__content {
    padding-right: 6px;
}

.deal-thumbnail {
    width: 88px;
    height: 88px;
}

.title-graphic {
    max-width: calc(100% - 56px);
    height: auto;
    ;
}

.max-width-100 {
    max-width: 100%;
    height: auto;
    ;
}

.v-btn--large.x-large {
    font-size: 18px !important;
    height: 64px !important;
}
</style>